import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

const MainWrap = styled.main`
    width: 90%;
    max-width: 60em;
    margin: 0 auto;
    padding: 2rem 0;
`

export const LegalContainer = props => <MainWrap>{props.children}</MainWrap>

LegalContainer.propTypes = {
    children: node.isRequired
}

export const ConsentHeader = styled.strong`
    color: #666;
    font-weight: 400;
    display: block;
`

export const BoldParagraph = styled.p`
    font-weight: 700;
    margin: 0;
`

export const Paragraph = styled.p`
    margin: 2rem 0;
`

export const LegalEmailLink = styled.a`
    color:#000;
    text-decoration: underline;
`

export const OL = styled.ol`
    & & {
        margin-top: 1rem;
    }
`

export const LI = styled.li`
    margin-bottom: 2rem;
`

export const DocumentTitle = styled.h1`
    margin-top: 0;
`

export const H2 = styled.h2`
    font-size: 1.5rem;

    & + ${Paragraph} {
        margin-top: 1rem;
    }
`

export const H3 = styled.h3`
    font-size: 1rem;
`

export default LegalContainer
