import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../containers/default'
import Wrap from '../components/layout/wrap'
import { styles } from '../styles/globals'

import { DocumentTitle, Paragraph } from '../components/legal-formatting'

const UpperWrap = styled.div`
    background-color: ${styles.bannerBackground};
    text-align: center;
    font-size: 1.56rem;
    color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
`

const CareerWrap = styled(Wrap)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    a {
        color: #fff;
    }
`

const Careers = () => (
    <DefaultLayout>
        <Helmet>
            <title>Careers at Jawbone Health</title>
            <meta
                http-equiv="refresh"
                content="0;url=https://jobs.lever.co/jawbonehealth"
            />
        </Helmet>
    </DefaultLayout>
)

export default Careers
